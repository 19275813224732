
import React from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { BarChart2, Loader2, MessageSquare, Bot, Copy, Printer, RefreshCw, CheckCircle, ListChecks, AlertCircle, Speech } from 'lucide-react';
import { toast } from '@/hooks/use-toast';
import { ModelSelector } from './agent-card/ModelSelector';
import { OpenRouterModel } from '@/utils/openRouter/types';
import { parseMarkdown } from '@/pages/labs/hooks/utils';

type ConversationEntry = {
  agent: string;
  message: string;
  model: string;
  persona: string;
};

interface ConversationAnalysisProps {
  conversation: ConversationEntry[];
  isLoading: boolean;
  isAnalyzing: boolean;
  analysisResults: string;
  handleAnalyzeConversation: (model?: string) => void;
  goToStep: (step: number) => void;
  analyzerModel?: string;
  availableModels: OpenRouterModel[];
  setAnalyzerModel: (model: string) => void;
}

export const ConversationAnalysis: React.FC<ConversationAnalysisProps> = ({
  conversation,
  isLoading,
  isAnalyzing,
  analysisResults,
  handleAnalyzeConversation,
  goToStep,
  analyzerModel,
  availableModels,
  setAnalyzerModel
}) => {
  const handleCopySummary = () => {
    if (analysisResults) {
      navigator.clipboard.writeText(analysisResults)
        .then(() => {
          toast({
            title: "Summary Copied",
            description: "The conversation summary has been copied to your clipboard.",
          });
        })
        .catch(err => {
          console.error("Failed to copy summary:", err);
          toast({
            title: "Copy Failed",
            description: "Failed to copy the summary. Please try again.",
            variant: "destructive",
          });
        });
    }
  };

  const handlePrintConversation = () => {
    if (!conversation.length || !analysisResults) return;

    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      toast({
        title: "Print Failed",
        description: "Unable to open print window. Please check your popup settings.",
        variant: "destructive",
      });
      return;
    }

    const conversationHtml = conversation.map((entry, index) => `
      <div style="margin-bottom: 20px; padding: 12px; border: 1px solid #e2e8f0; border-radius: 8px;">
        <h3 style="margin-top: 0; color: #4338ca; font-weight: bold;">${entry.agent} (${entry.persona})</h3>
        <p style="margin-bottom: 0;">${entry.message.replace(/\n/g, '<br/>')}</p>
        <div style="font-size: 0.75rem; color: #64748b; margin-top: 8px;">Model: ${entry.model.split('/').pop()}</div>
      </div>
    `).join('');

    printWindow.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
        <title>AI Agents Conversation Analysis</title>
        <meta charset="utf-8">
        <style>
          body { font-family: system-ui, -apple-system, sans-serif; line-height: 1.5; padding: 20px; max-width: 800px; margin: 0 auto; }
          h1 { color: #1e293b; margin-bottom: 30px; }
          h2 { color: #334155; margin-top: 30px; margin-bottom: 15px; }
          hr { border: 0; height: 1px; background: #e2e8f0; margin: 30px 0; }
          .analysis { white-space: pre-wrap; background-color: #f8fafc; padding: 15px; border-radius: 8px; }
        </style>
      </head>
      <body>
        <h1>AI Agents Conversation Analysis</h1>
        
        <h2>Conversation Analysis</h2>
        <div class="analysis">${analysisResults.replace(/\n/g, '<br/>')}</div>
        
        <hr>
        
        <h2>Full Conversation</h2>
        ${conversationHtml}
        
        <footer style="margin-top: 50px; font-size: 0.8rem; color: #94a3b8; text-align: center;">
          Generated by AI Agents Lab on ${new Date().toLocaleDateString()}
        </footer>
      </body>
      </html>
    `);

    printWindow.document.close();
    
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 500);
  };

  const modelsByProvider = availableModels.reduce((acc, model) => {
    if (!acc[model.provider]) {
      acc[model.provider] = [];
    }
    acc[model.provider].push(model);
    return acc;
  }, {} as Record<string, any[]>);

  if (conversation.length === 0) {
    return (
      <Card className="mb-12 overflow-hidden border-2 border-gray-200">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <BarChart2 className="h-5 w-5 text-gray-600" />
            Conversation Analysis
          </CardTitle>
        </CardHeader>
        <CardContent className="py-6">
          <div className="flex flex-col items-center justify-center p-8 text-center">
            <MessageSquare className="h-12 w-12 text-gray-300 mb-4" />
            <p className="text-lg font-medium text-gray-600 mb-2">No conversation to analyze</p>
            <p className="text-gray-500 mb-6">Start a conversation between AI agents first to see analysis.</p>
            <Button onClick={() => goToStep(2)} variant="outline">
              Go to Agent Configuration
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="mb-12 overflow-hidden border-2 border-gray-200">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <BarChart2 className="h-5 w-5 text-gray-600" />
            Conversation Analysis
          </div>
          
          {/* Moved model selector to the header for better space utilization */}
          <div className="flex items-center gap-3">
            {analyzerModel && !isAnalyzing && (
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <Bot className="h-4 w-4" />
                <span>Analyzed by: {analyzerModel.split('/').pop()?.replace(/:.*$/, '')}</span>
              </div>
            )}
            
            {!isAnalyzing && analysisResults && (
              <div className="flex items-center gap-2">
                <ModelSelector
                  agentModel={analyzerModel || ''}
                  setAgentModel={setAnalyzerModel}
                  modelsByProvider={modelsByProvider}
                  loadingModels={false}
                  isDisabled={isAnalyzing}
                />
                <Button 
                  onClick={() => handleAnalyzeConversation()} 
                  size="sm"
                  className="h-8"
                  disabled={isAnalyzing || !analyzerModel}
                  variant="outline"
                >
                  <RefreshCw className="h-3.5 w-3.5 mr-1" />
                  Re-analyze
                </Button>
              </div>
            )}
          </div>
        </CardTitle>
      </CardHeader>
      <div className="px-6">
        <Separator />
      </div>
      <CardContent className="py-6">
        {isAnalyzing ? (
          <div className="flex flex-col items-center justify-center p-8">
            <Loader2 className="h-10 w-10 animate-spin text-purple-500 mb-4" />
            <p className="text-gray-600">Analyzing conversation{analyzerModel ? ` with ${analyzerModel.split('/').pop()?.replace(/:.*$/, '')}` : ''}...</p>
          </div>
        ) : !analysisResults ? (
          <div className="flex flex-col items-center justify-center p-8">
            <div className="w-full max-w-md mb-6">
              <h3 className="text-sm font-medium mb-2">Select Analysis Model</h3>
              <ModelSelector
                agentModel={analyzerModel || ''}
                setAgentModel={setAnalyzerModel}
                modelsByProvider={modelsByProvider}
                loadingModels={false}
                isDisabled={false}
              />
              <p className="text-xs text-gray-500 mt-2">
                Choose the AI model that will analyze your conversation.
              </p>
            </div>
            <p className="text-gray-600 mb-6">
              Click the button below to analyze the conversation between the AI agents. This will evaluate the quality, insights, and coverage of the discussion.
            </p>
            <Button 
              onClick={() => handleAnalyzeConversation()} 
              className="bg-purple-600 hover:bg-purple-700"
              disabled={isLoading || !analyzerModel}
            >
              Analyze Conversation
            </Button>
          </div>
        ) : (
          <div className="space-y-6">
            {/* Full width for analysis results */}
            <div>
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-lg font-medium">Analysis Results</h3>
                <div className="flex gap-2">
                  <Button variant="outline" size="sm" onClick={handleCopySummary} className="h-8">
                    <Copy className="h-3.5 w-3.5 mr-1" />
                    Copy
                  </Button>
                  <Button 
                    onClick={handlePrintConversation} 
                    variant="outline"
                    size="sm"
                    className="h-8"
                  >
                    <Printer className="h-3.5 w-3.5 mr-1" />
                    Print
                  </Button>
                </div>
              </div>
              <div 
                className="text-gray-700 bg-gray-50 p-5 rounded-md border border-gray-200"
                dangerouslySetInnerHTML={{ __html: parseMarkdown(analysisResults) }}
              />
              <div className="flex flex-wrap gap-3 mt-4">
                <div className="flex items-center text-sm bg-purple-50 text-purple-700 px-3 py-1 rounded-full">
                  <CheckCircle className="h-3.5 w-3.5 mr-1.5" />
                  <span>Key Insights</span>
                </div>
                <div className="flex items-center text-sm bg-blue-50 text-blue-700 px-3 py-1 rounded-full">
                  <ListChecks className="h-3.5 w-3.5 mr-1.5" />
                  <span>Discussion Points</span>
                </div>
                <div className="flex items-center text-sm bg-amber-50 text-amber-700 px-3 py-1 rounded-full">
                  <AlertCircle className="h-3.5 w-3.5 mr-1.5" />
                  <span>Areas of Disagreement</span>
                </div>
                <div className="flex items-center text-sm bg-emerald-50 text-emerald-700 px-3 py-1 rounded-full">
                  <Speech className="h-3.5 w-3.5 mr-1.5" />
                  <span>Further Exploration</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </CardContent>
      <CardFooter className="pt-0">
        <Button
          onClick={() => goToStep(3)}
          variant="outline"
          className="mr-auto"
        >
          Back to Conversation
        </Button>
      </CardFooter>
    </Card>
  );
};

